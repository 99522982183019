// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    const header = document.querySelector( 'header' );

    if ( !header ) return;

    const brandbar = document.querySelector( '.brandbar' );

    if ( !brandbar ) return;

    const main = document.querySelector( 'main' );

    if ( !main ) return;

    const visibleHeight = 4;
    const brandbarHeight = brandbar.clientHeight - visibleHeight;

    const fixHeaderByScrollPosition = ( brandbarHeight ) => {
        const isScrolledToBrandbarBottom = window.pageYOffset >= brandbarHeight;
        const CSS_IS_FIXED = 'is-fixed';

        if ( isScrolledToBrandbarBottom ) {
            header.classList.add( CSS_IS_FIXED );
            main.classList.add( CSS_IS_FIXED );
        } else {
            header.classList.remove( CSS_IS_FIXED );
            main.classList.remove( CSS_IS_FIXED );
        }
    };

    window.addEventListener( 'scroll', () => {
        fixHeaderByScrollPosition(brandbarHeight);
    });

    window.addEventListener( 'resize', () => {
        fixHeaderByScrollPosition( brandbarHeight );
    } );
} )();
